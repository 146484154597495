import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { BaseComponent } from '../base/base.component';

@Component({
  selector: 'app-simple-select-component',
  template: '',
})
export abstract class SimpleSelectComponent<T> extends BaseComponent implements OnInit, OnDestroy {
  @Input() control: AbstractControl = new FormControl('');
  @Input() label: string | null = null;
  @Input() placeholder: string | null = null;
  @Input() hint: string | null = null;
  @Input() appearance: MatFormFieldAppearance = 'fill';

  public values: Array<T> = [];

  constructor() {
    super();
  }

  public override ngOnInit(): void {
    super.ngOnInit();
  }

  public override ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  public abstract fetchInitialValues(): void;

  public abstract getDisplayName(value: T): string;
}
