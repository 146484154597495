import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { AuthService } from '../auth';
import { AppAbility } from '../auth/permissions';
import defineAbilitiesFor from '../auth/permissions/app-ability';
import { User } from '../models';

@Injectable({
  providedIn: 'root',
})
export class AppInitService {
  rules = defineAbilitiesFor(new User(0, 'admin', 'admin'));

  constructor(private ability: AppAbility) {}

  public init(authService: AuthService): Observable<boolean> {
    this.ability.update(this.rules);

    return of(true);
  }
}
