import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable()
export class PublicAuthInterceptor implements HttpInterceptor {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const authReq = req.url.includes(environment.publicApi.baseUrl)
      ? req.clone({ headers: req.headers.append('apiKey', environment.publicApi.apiKey) })
      : req.clone({});

    return next.handle(authReq);
  }
}
