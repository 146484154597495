import parse from 'date-fns/parse';
import { TimeFrameInterface } from '../http/interfaces';
import { Athlete } from './athlete.model';

export class TimeFrame {
  constructor(
    public id: number,
    public uuid: string,
    public startTime: Date,
    public endTime: Date,
    public deviceID: number,
    public activityID: number,
    public athlete: Athlete,
    public hasPaid: boolean,
    public bibNumber: string,
    public trackerType: 'athlete' | 'staff'
  ) {}

  public get bibNumberDisplay(): string {
    return `#${this.bibNumber}`;
  }

  public static fromInterface(frame: TimeFrameInterface): TimeFrame {
    const hasPaid = frame.params && frame.params.has_paid ? frame.params.has_paid : false;
    const bibNumber = frame.params && frame.params.bib_number ? frame.params.bib_number : '-';
    const trackerType = frame.params && frame.params.tracker_type ? frame.params.tracker_type : `athlete`;

    const a = new TimeFrame(
      frame.id,
      frame.uuid,
      parse(frame.start_time, 'yyyy-MM-dd', new Date()),
      parse(frame.end_time, 'yyyy-MM-dd', new Date()),
      frame.device,
      frame.activity,
      Athlete.fromInterface(frame.athlete),
      hasPaid,
      bibNumber,
      trackerType
    );

    return a;
  }
}
