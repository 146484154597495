import { Ability, AbilityBuilder, AbilityClass, ExtractSubjectType } from '@casl/ability';
import { User } from '../../models';

export type PermissionActions = 'add' | 'view' | 'change' | 'delete' | 'manage';
export type PermissionSubjects = User | typeof User | 'activity' | 'all';

export type AppAbility = Ability<[PermissionActions, PermissionSubjects]>;
export const AppAbility = Ability as AbilityClass<AppAbility>;

export default function defineAbilitiesFor(user?: User) {
  const { can, rules } = new AbilityBuilder(AppAbility);

  if (user) {
    can('manage', 'all');
  }

  return rules;
}

/**
 * @see https://casl.js.org/v5/en/guide/subject-type-detection
 * @see https://github.dev/stalniy/casl-examples/blob/master/packages/angular-todo/
 */
export function createAbility() {
  const { build } = new AbilityBuilder(AppAbility);

  return build({
    detectSubjectType: (object) => object.constructor as ExtractSubjectType<PermissionSubjects>,
  });
}
