import { Injectable } from '@angular/core';
import { User } from '../../models';
import { UserInterface } from '../interfaces';
import { AbstractSerializer } from './abstract-serializer';

@Injectable({
  providedIn: 'root',
})
export class UserSerializer extends AbstractSerializer<User, UserInterface> {
  constructor() {
    super();
  }

  serialize(input: User): UserInterface {
    return {
      id: input.id,
      username: input.username,
      email: input.email,
    };
  }

  deserialize(input: UserInterface): User {
    return new User(input.id, input.username, input.email);
  }
}
