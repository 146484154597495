<ng-container *transloco="let t; read: 'page-not-found-layout'">
  <div class="container">
    <div class="row authentication-container">
      <p class="text-center">
        <mat-icon>report</mat-icon>
      </p>
      <div class="col-sm-9 col-md-8 col-lg-7 col-xl-6 col-xxl-5 d-flex justify-content-center">
        <img
          [src]="darkThemeEnabled ? 'assets/images/logo_white.png' : 'assets/images/logo_black.png'"
          class="img-fluid"
          alt="Logo"
        />
      </div>
      <div class="col-12"></div>
      <div class="col-sm-9 col-md-8 col-lg-7 col-xl-6 col-xxl-5">
        <mat-card class="my-class">
          <mat-card-header>
            <mat-card-title>{{ t('title') }}</mat-card-title>
            <mat-card-subtitle>{{ t('subtitle') }}</mat-card-subtitle>
          </mat-card-header>
          <mat-card-content>
            <p class="pt-3">{{ t('message') }}</p>

            <div>
              <mat-divider></mat-divider>
            </div>
          </mat-card-content>
          <mat-card-actions>
            <button mat-button (click)="returnToPreviousPage()">{{ 'labels.back' | transloco }}</button>
          </mat-card-actions>
        </mat-card>
      </div>
    </div>
  </div>
</ng-container>
