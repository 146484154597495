export interface AthleteComputedDataInterface {
  timeframe: number;
  data: {
    rank?: number | null;

    travelledDistance?: number | null;
    distanceToFinish?: number | null;

    longitudeMM: number;
    latitudeMM: number;
    altitudeMM?: number | null;

    longitude?: number | null;
    latitude?: number | null;
    altitude?: number | null;

    currentPower?: number[] | null;
    currentSpeed?: number[] | null;

    avgSpeed?: number | null;
    avgPower?: number | null;

    cadence?: number | null;
    eta?: number | null;

    first_timestamp: number;
    last_timestamp: number;
    last_computation: number;

    distanceToLeader?: number | null;
    timeToLeader?: number | null;
    gradient?: number | null;
    simulation?: 'On' | 'Off' | null;
  } | null;
}

export function instanceOfAthleteComputedData(object: object): object is AthleteComputedDataInterface {
  try {
    return 'timeframe' in object && 'data' in object;
  } catch (error) {
    return false;
  }
}

export function instanceOfAthleteComputedDataArray(object: object): object is AthleteComputedDataInterface[] {
  try {
    return Array.isArray(object) && object.length > 0 && instanceOfAthleteComputedData(object[0]);
  } catch (error) {
    return false;
  }
}
