export const environment = {
  production: true,
  api: {
    baseUrl: 'https://api.insiders.live/asi/api/v1/',
  },
  publicApi: {
    baseUrl: 'https://api.insiders.live/v1/public/',
    baseWsUrl: 'wss://api.insiders.live/v1/public/',
    apiKey: 'API_KEY',
  },
  oauth: {
    tokenEndpoint: 'https://api.insiders.live/asi/oauth2/token/',
    issuer: 'https://api.insiders.live/asi/oauth2/token/',
    redirectUri: 'https://platform.insiders.live/',
    clientId: 'CLIENT_ID',
    dummyClientSecret: 'CLIENT_SECRET',
    scope: 'all',
    requireHttps: true,
    showDebugInformation: true,
    oidc: false,
    requestAccessToken: true,
    sendAccessToken: true,
    allowedUrls: ['https://api.insiders.live/asi/api/v1/'],
    resourceServer: {
      allowedUrls: ['https://api.insiders.live/asi/api/v1/'],
      sendAccessToken: true,
    },
  },
  sentry: {
    dsn: 'https://24097c981cd34e58bb22951657759942@sentry.vnv.ch/222',
    release: 'insiders-race-livestream-frontend@a1fb2b286fcaba5454a33e363a3ba3e37ca19cc5',
  },
};
