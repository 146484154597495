<ng-template appDynamicComponent></ng-template>
<ng-container *transloco="let t">
  <div class="main-container">
    <mat-toolbar color="primary" id="toolbar">
      <!-- <button id="menu-button" mat-icon-button (click)="snav.toggle()">
        <mat-icon>menu</mat-icon>
      </button> -->
      <div id="menu-button"></div>
      <div id="logo" [routerLink]="['/home']">
        <img src="assets/images/logo_white.png" alt="" />
      </div>
      <div id="profile-button"></div>
      <!-- <button id="profile-button" mat-icon-button [matTooltip]="currentUser?.username ?? 'Anonymous'">
        <mat-icon>person</mat-icon>
      </button> -->
    </mat-toolbar>
    <mat-sidenav-container class="main-sidenav-container" autosize>
      <mat-sidenav
        #snav
        [opened]="false"
        [mode]="mobileQuery.matches ? 'over' : 'side'"
        [fixedInViewport]="mobileQuery.matches"
        fixedTopGap="56"
      >
        <mat-list>
          <h4 class="ps-3 py-3">{{ t('app-name') }}</h4>
          <mat-divider></mat-divider>
          <!-- <div mat-subheader>{{ t('menu.management.title') }}</div>
          <mat-list-item class="main-menu-item" routerLink="/activity" routerLinkActive="active">
            <mat-icon matListItemIcon>dashboard</mat-icon>
            <div matListItemTitle>
              {{ t('menu.management.dashboard-title') }}
            </div>
            <div matListItemLine>
              {{ t('menu.management.dashboard-description') }}
            </div>
          </mat-list-item>
          <mat-divider></mat-divider> -->
          <div mat-subheader>{{ t('menu.others.title') }}</div>
          <mat-list-item>
            <mat-icon matListItemIcon>nights_stay</mat-icon>
            <div matListItemLine>
              <mat-slide-toggle
                class="ms-auto"
                labelPosition="before"
                [disabled]="true"
                [(ngModel)]="darkThemeEnabled"
                (change)="toggleTheme($event)"
                >{{ t('menu.others.darkmode-title') }}</mat-slide-toggle
              >
            </div>
            <div matListItemLine>
              {{ t('menu.others.darkmode-description') }}
            </div>
          </mat-list-item>
          <mat-list-item>
            <mat-icon matListItemIcon>translate</mat-icon>
            <div matListItemLine>
              <mat-form-field class="d-flex flex-column">
                <mat-label>{{ t('menu.others.translate-title') }}</mat-label>
                <mat-select (selectionChange)="changeLanguage($event)" [(value)]="activeLang">
                  <mat-option value="fr">{{ t('menu.others.translate-french') }}</mat-option>
                  <mat-option value="en">{{ t('menu.others.translate-english') }}</mat-option>
                </mat-select>
                <mat-hint>{{ t('menu.others.translate-description') }}</mat-hint>
              </mat-form-field>
            </div>
          </mat-list-item>
          <mat-divider class="mt-4"></mat-divider>
          <div mat-subheader>{{ t('menu.account.title') }}</div>
          <mat-list-item class="main-menu-item" (click)="logout()">
            <mat-icon matListItemIcon>logout</mat-icon>
            <div matListItemLine>{{ t('menu.account.logout-title') }}</div>
            <div matListItemLine>
              {{ t('menu.account.logout-description') }}
            </div>
          </mat-list-item>
        </mat-list>
      </mat-sidenav>
      <mat-sidenav-content class="main-content-container">
        <div class="router-container">
          <router-outlet></router-outlet>
        </div>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </div>
</ng-container>
