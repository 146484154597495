import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, map, Observable, of, switchMap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TimeFrame } from '../../models';
import { LoggerService } from '../../services';
import { TimeFrameInterface } from '../interfaces';
import { HttpService } from './abstract-http-service';

@Injectable({
  providedIn: 'root',
})
export class TimeFramesService extends HttpService<TimeFrame> {
  private _data: BehaviorSubject<TimeFrame[] | null> = new BehaviorSubject<TimeFrame[] | null>(null);

  protected override baseUrl = `${environment.publicApi.baseUrl}timeframes`;

  constructor(protected override http: HttpClient, protected override logger: LoggerService) {
    super(http, logger);
  }

  public override list(parameters: object): Observable<TimeFrame[]> {
    throw new Error('Method not implemented.');
  }
  public override get(id: string | number, parameters: object): Observable<TimeFrame | null> {
    throw new Error('Method not implemented.');
  }

  public listActivityTimeFrames(activityID: number, parameters: object = {}, refresh = false): Observable<TimeFrame[]> {
    return this._data.pipe(
      switchMap((values) => {
        if (values && refresh === false) {
          return of(values);
        } else {
          let params = this.generateHttpParams(parameters);
          params = params.append('with_athlete', true);

          return this.http
            .get<TimeFrameInterface[]>(`${this.baseUrl}/${activityID}`, {
              params: params,
            })
            .pipe(
              catchError(() => of(null)),
              map((result) => {
                if (result) {
                  const items: TimeFrame[] = result.map((t) => TimeFrame.fromInterface(t));
                  this._data.next(items);

                  return items;
                } else {
                  this._data.next([]);
                  return [];
                }
              })
            );
        }
      })
    );
  }

  public get data(): Observable<TimeFrame[] | null> {
    return this._data.asObservable();
  }

  public set $data(timeFrames: TimeFrame[] | null) {
    this._data.next(timeFrames);
  }

  public get hasPaidData(): Observable<TimeFrame[]> {
    return this._data
      .asObservable()
      .pipe(map((v) => (v ? v.filter((t) => t.hasPaid && t.trackerType === 'athlete') : [])));
  }

  public get athletesData(): Observable<TimeFrame[]> {
    return this._data.asObservable().pipe(map((v) => (v ? v.filter((t) => t.trackerType === 'athlete') : [])));
  }

  public get currentData(): TimeFrame[] | null {
    return this._data.getValue();
  }
}
