import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { PureAbility } from '@casl/ability';
import {
  AuthConfig,
  DefaultOAuthInterceptor,
  OAuthLogger,
  OAuthModule,
  OAuthNoopResourceServerErrorHandler,
  OAuthResourceServerErrorHandler,
  OAuthService,
  OAuthStorage,
} from 'angular-oauth2-oidc';
import { authConfig, createDefaultLogger, createDefaultStorage, OAuthModuleCustomConfig } from './auth.config';
import { AppAbility, createAbility } from './permissions';

@NgModule({
  declarations: [],
  imports: [CommonModule, OAuthModule.forRoot(new OAuthModuleCustomConfig())],
  providers: [
    OAuthService,
    {
      provide: AuthConfig,
      useValue: authConfig,
    },
    {
      provide: OAuthLogger,
      useFactory: createDefaultLogger,
    },
    {
      provide: OAuthStorage,
      useFactory: createDefaultStorage,
    },
    {
      provide: OAuthResourceServerErrorHandler,
      useClass: OAuthNoopResourceServerErrorHandler,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DefaultOAuthInterceptor,
      deps: [OAuthStorage, OAuthResourceServerErrorHandler],
      multi: true,
    },
    { provide: AppAbility, useFactory: createAbility },
    { provide: PureAbility, useExisting: AppAbility },
  ],
})
export class AuthModule {}
