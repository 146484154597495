import { MediaMatcher } from '@angular/cdk/layout';
import { DOCUMENT } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatSelectChange } from '@angular/material/select';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { TranslocoService } from '@ngneat/transloco';
import { enUS, frCH } from 'date-fns/locale';
import { takeUntil } from 'rxjs';
import { BaseMaterialLayoutComponent } from 'src/app/shared/components/base';
import { AuthService } from '../../auth';
import { User } from '../../models';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss'],
})
export class MainLayoutComponent extends BaseMaterialLayoutComponent implements OnInit, OnDestroy {
  public activeLang = 'en';
  public currentUser: User | null = null;

  public mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;

  constructor(
    @Inject(MAT_DATE_LOCALE) private _locale: Locale,
    @Inject(DOCUMENT) protected override document: Document,
    protected override media: MediaMatcher,
    private _adapter: DateAdapter<Date>,
    private transloco: TranslocoService,
    private authService: AuthService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    super(document, media);

    this.mobileQuery = this.media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => this.changeDetectorRef.detectChanges();
    this.mobileQuery.addEventListener('change', this._mobileQueryListener);
  }

  override ngOnInit(): void {
    super.ngOnInit();

    this.activeLang = this.transloco.getActiveLang();

    this.transloco.langChanges$.pipe(takeUntil(this.unsubscribe)).subscribe({
      next: (value) => {
        switch (value) {
          case 'fr':
            this._locale = frCH;
            break;
          case 'en':
            this._locale = enUS;
            break;
          default:
            this._locale = enUS;
            break;
        }

        this._adapter.setLocale(this._locale);
      },
    });
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();

    this.mobileQuery.removeEventListener('change', this._mobileQueryListener);
  }

  public toggleTheme(value: MatSlideToggleChange): void {
    value && value.checked
      ? this.document.body.classList.add('theme-dark')
      : this.document.body.classList.remove('theme-dark');
  }

  public changeLanguage(lang: MatSelectChange): void {
    if (lang.value) {
      this.transloco.setActiveLang(lang.value);
    }
  }

  public logout(): void {
    this.authService.logout();
  }
}
