import { MediaMatcher } from '@angular/cdk/layout';
import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { BaseComponent } from './base.component';

@Component({
  selector: 'app-base-material-layout',
  template: ``,
})
export class BaseMaterialLayoutComponent extends BaseComponent implements OnInit, OnDestroy {
  public darkThemeEnabled = true;

  /* public themeQuery: MediaQueryList;
  private _themeQueryListener: (event: MediaQueryListEvent) => void; */

  constructor(@Inject(DOCUMENT) protected document: Document, protected media: MediaMatcher) {
    super();

    this.darkThemeEnabled
      ? this.document.body.classList.add('theme-dark')
      : this.document.body.classList.remove('theme-dark');

    /* this.darkThemeEnabled = this.document.body.classList.contains('theme-dark');

    this.themeQuery = this.media.matchMedia('(prefers-color-scheme: dark)');
    this._themeQueryListener = (event: MediaQueryListEvent) => {
      this.darkThemeEnabled = event.matches;
      this.darkThemeEnabled
        ? this.document.body.classList.add('theme-dark')
        : this.document.body.classList.remove('theme-dark');
    };
    this.themeQuery.addEventListener('change', this._themeQueryListener); */
  }

  override ngOnInit(): void {
    super.ngOnInit();
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();

    /* this.themeQuery.removeEventListener('change', this._themeQueryListener); */
  }
}
