import { Buffer } from 'buffer';
import parseISO from 'date-fns/parseISO';
import { parseString } from 'xml2js';
import { ActivityInterface } from '../http/interfaces';

const EARTH_RADIUS = 6371e3; // Earth's radius in meters

export class Position {
  public distanceFromStart = 0;
  constructor(public latitude: number, public longitude: number, public elevation: number) {}

  get latitudeRad(): number {
    return (this.latitude * Math.PI) / 180;
  }

  get longitudeRad(): number {
    return (this.longitude * Math.PI) / 180;
  }
}

export class PointInterest extends Position {
  constructor(
    public override latitude: number,
    public override longitude: number,
    public override elevation: number,
    public symbole: string,
    public text: string
  ) {
    super(latitude, longitude, elevation);
  }
}

export class Activity {
  constructor(
    public id: number,
    public uuid: string,
    public name: string,
    public gpx: Position[],
    public wpt: PointInterest[] | null,
    public startTime: Date,
    public endTime: Date,
    public nbAthletes: string,
    public distance: string,
    public elevationGain: string,
    public primaryColor: string,
    public secondaryColor: string,
    public logo: string,
    public sport: string,
    public debug: boolean,
    public displayElevationPanel: boolean,
    public displayOnHomePage: boolean,
    public minSpeed: number,
    public maxSpeed: number,
    public minPower: number,
    public maxPower: number,
    public location: string,
    public description: string
  ) {}

  public get sportLogo(): string {
    switch (this.sport) {
      case 'swimming':
        return 'assets/images/sports/swimming.svg';
      case 'running':
        return 'assets/images/sports/running.svg';
      case 'trailrunning':
        return 'assets/images/sports/trailrunning.svg';
      case 'biking':
        return 'assets/images/sports/biking.svg';
      default:
        return 'assets/images/sports/biking.svg';
    }
  }

  public static fromInterface(activity: ActivityInterface): Activity {
    let positions: Position[] = [];
    let pointInterets: PointInterest[] | null = null;

    // Decode and parse GPX file
    if ('gpx' in activity.json && typeof activity.json.gpx === 'string') {
      const decodedBuffer = Buffer.from(activity.json.gpx, 'base64');
      const gpxString = decodedBuffer.toString('utf-8');

      parseString(gpxString, (err, result) => {
        if (err) {
          console.error('Erreur de parsing XML :', err);
          return;
        }

        const gpxData = result.gpx;
        const trkpts = gpxData.trk[0].trkseg[0].trkpt;
        const wptpts = gpxData.wpt;
        try {
          positions = trkpts
            .filter((trkpt: any) => !!trkpt.$ && !!trkpt.ele)
            .map((trkpt: any) => new Position(Number(trkpt.$.lat), Number(trkpt.$.lon), Number(trkpt.ele[0])));

          if (wptpts) {
            pointInterets = [];
            pointInterets = wptpts.map(
              (wptpt: any) =>
                new PointInterest(
                  Number(wptpt.$.lat),
                  Number(wptpt.$.lon),
                  Number(wptpt.ele[0]),
                  wptpt.sym[0],
                  wptpt.desc[0] && wptpt.desc[0] !== '' ? wptpt.desc[0] : ''
                )
            );
          }
        } catch (error) {
          console.error('Erreur de parsing GPX File :', trkpts);
        }
      });

      // Compute distance from start
      const initPos = positions[0];
      positions.reduce((prev, curr) => {
        curr.distanceFromStart = prev.distanceFromStart + this.computeDistance(prev, curr);
        return curr;
      }, initPos);
    }

    // Add static data for specific event
    const staticData = this.getStaticData(activity.json);
    const a = new Activity(
      activity.id,
      activity.uuid,
      activity.name,
      positions,
      pointInterets,
      staticData.startTime,
      staticData.endTime,
      staticData.nbAthletes,
      staticData.distance,
      staticData.elevationGain,
      staticData.primaryColor,
      staticData.secondaryColor,
      staticData.logo,
      staticData.sport,
      staticData.debug,
      staticData.displayElevationPanel,
      staticData.displayOnHomePage,
      staticData.minSpeed,
      staticData.maxSpeed,
      staticData.minPower,
      staticData.maxPower,
      staticData.location,
      staticData.description
    );

    return a;
  }

  private static getStaticData(baseActivity: object): {
    startTime: Date;
    endTime: Date;
    nbAthletes: string;
    distance: string;
    elevationGain: string;
    primaryColor: string;
    secondaryColor: string;
    logo: string;
    sport: string;
    debug: boolean;
    displayElevationPanel: boolean;
    displayOnHomePage: boolean;
    minPower: number;
    maxPower: number;
    minSpeed: number;
    maxSpeed: number;
    location: string;
    description: string;
  } {
    return {
      startTime:
        'startingTime' in baseActivity && typeof baseActivity.startingTime === 'string'
          ? parseISO(baseActivity.startingTime)
          : parseISO('1900-01-1T100:00:00'),
      endTime:
        'endingTime' in baseActivity && typeof baseActivity.endingTime === 'string'
          ? parseISO(baseActivity.endingTime)
          : parseISO('1900-01-1T123:00:00'),
      nbAthletes:
        'nbAthletes' in baseActivity && typeof baseActivity.nbAthletes === 'number'
          ? baseActivity.nbAthletes.toString()
          : '-',
      distance: 'distance' in baseActivity && typeof baseActivity.distance === 'string' ? baseActivity.distance : '-',
      elevationGain:
        'elevationGain' in baseActivity && typeof baseActivity.elevationGain === 'string'
          ? baseActivity.elevationGain
          : '-',
      primaryColor:
        'primaryColor' in baseActivity && typeof baseActivity.primaryColor === 'string'
          ? baseActivity.primaryColor
          : '-',
      secondaryColor:
        'secondaryColor' in baseActivity && typeof baseActivity.secondaryColor === 'string'
          ? baseActivity.secondaryColor
          : '-',
      logo: 'logo' in baseActivity && typeof baseActivity.logo === 'string' ? baseActivity.logo : '-',
      sport: 'sport' in baseActivity && typeof baseActivity.sport === 'string' ? baseActivity.sport : '-',
      debug: 'debug' in baseActivity && typeof baseActivity.debug === 'boolean' ? baseActivity.debug : false,
      displayElevationPanel:
        'displayElevationPanel' in baseActivity && typeof baseActivity.displayElevationPanel === 'boolean'
          ? baseActivity.displayElevationPanel
          : false,
      displayOnHomePage:
        'displayOnHomePage' in baseActivity && typeof baseActivity.displayOnHomePage === 'boolean'
          ? baseActivity.displayOnHomePage
          : false,
      minPower: 'minPower' in baseActivity && typeof baseActivity.minPower === 'number' ? baseActivity.minPower : 0,
      maxPower: 'maxPower' in baseActivity && typeof baseActivity.maxPower === 'number' ? baseActivity.maxPower : 30,
      minSpeed: 'minSpeed' in baseActivity && typeof baseActivity.minSpeed === 'number' ? baseActivity.minSpeed : 0,
      maxSpeed: 'maxSpeed' in baseActivity && typeof baseActivity.maxSpeed === 'number' ? baseActivity.maxSpeed : 20,
      location: 'location' in baseActivity && typeof baseActivity.location === 'string' ? baseActivity.location : '-',
      description:
        'description' in baseActivity && typeof baseActivity.description === 'string'
          ? baseActivity.description
          : 'No description found.',
    };
  }

  private static computeDistance(a: Position, b: Position): number {
    // Compute distance in meters between two positions using Haversine formula
    const deltaLat = b.latitudeRad - a.latitudeRad;
    const deltaLon = b.longitudeRad - a.longitudeRad;
    const aVal =
      Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) +
      Math.cos(a.latitudeRad) * Math.cos(b.latitudeRad) * Math.sin(deltaLon / 2) * Math.sin(deltaLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(aVal), Math.sqrt(1 - aVal));
    const elevationDifference = Math.abs(b.elevation - a.elevation);
    const distance = Math.sqrt(Math.pow(EARTH_RADIUS * c, 2) + Math.pow(elevationDifference, 2));
    return distance;
  }
}

export interface ActivityCreate extends Omit<Activity, 'id' | 'uuid'> {}
export interface ActivityUpdate extends ActivityCreate {}
