import { Component, OnInit, signal } from '@angular/core';
import { takeUntil } from 'rxjs';
import { BaseComponent } from 'src/app/shared/components/base';
import { ActivityService } from '../../http/services';
import { Activity } from '../../models';

@Component({
  selector: 'app-loader-layout',
  template: `
    <div class="loader-container">
      <div class="semicircle">
        <div>
          <div>
            <div>
              <div>
                <div>
                  <div>
                    <div>
                      <div></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="logo">
        <img src="/assets/images/logo_white.png" class="img-responsive insiders-logo" alt="" />
        <ng-container *ngIf="activity(); let a">
          <span>X</span>
          <img [src]="a.logo" class="img-responsive activity-logo" alt="" />
        </ng-container>
      </div>
    </div>
  `,
  styleUrls: ['./loader-layout.component.scss'],
})
export class LoaderLayoutComponent extends BaseComponent implements OnInit {
  public activity = signal<Activity | null>(null);

  constructor(private activityService: ActivityService) {
    super();
  }

  override ngOnInit(): void {
    super.ngOnInit();

    this.activityService.data.pipe(takeUntil(this.unsubscribe)).subscribe({
      next: (a) => {
        this.activity.set(a);
      },
    });
  }
}
