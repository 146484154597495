import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { LoggerService } from '../../services';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export abstract class HttpService<Model> {
  protected baseUrl: string = environment.api.baseUrl;

  constructor(protected http: HttpClient, protected logger: LoggerService) {}

  public abstract list(parameters: object): Observable<Model[]>;
  public abstract get(id: string | number, parameters: object): Observable<Model | null>;

  /**
   * Handle Http operation that failed.
   *
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  protected handleError(
    operation: 'list' | 'get' | 'update' | 'delete',
    error: HttpErrorResponse
  ): Observable<HttpErrorResponse> {
    this.logger.error(`
    Error operation : ${operation}\n
    Error code : ${error.status}\n
    Error status : ${error.statusText}\n
    Error message : ${error.message}\n
    Error stacktrace : ${error.error}\n
    `);

    return of(error);
  }

  protected generateHttpParams<T extends object>(parameters: T): HttpParams {
    let params = new HttpParams();

    (Object.keys(parameters) as (keyof T)[]).forEach((key) => {
      const value = parameters[key];
      if (
        value &&
        ((typeof value === 'string' && value !== '') || typeof value === 'number' || typeof value === 'boolean')
      ) {
        params = params.append(key.toString(), value);
      }
    });

    return params;
  }
}
