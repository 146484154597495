import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, map, of, switchMap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Activity } from '../../models';
import { LoggerService } from '../../services';
import { ActivityInterface } from '../interfaces';
import { HttpService } from './abstract-http-service';

@Injectable({
  providedIn: 'root',
})
export class ActivityService extends HttpService<Activity> {
  private _data: BehaviorSubject<Activity | null> = new BehaviorSubject<Activity | null>(null);

  protected override baseUrl = `${environment.publicApi.baseUrl}activities`;

  constructor(protected override http: HttpClient, protected override logger: LoggerService) {
    super(http, logger);
  }

  public override list(parameters: object): Observable<Activity[]> {
    const params = this.generateHttpParams(parameters);

    return this.http
      .get<ActivityInterface[]>(`${this.baseUrl}`, {
        params: params,
      })
      .pipe(
        catchError(() => of(null)),
        map((result) => {
          if (result) {
            const items: Activity[] = result.map((t) => Activity.fromInterface(t));
            return items;
          } else {
            return [];
          }
        })
      );
  }

  public get(id: string | number, parameters: object = {}, refresh = false): Observable<Activity | null> {
    return this._data.pipe(
      switchMap((value) => {
        if (value && value.id === id && refresh === false) {
          return of(value);
        } else {
          return this.http
            .get<ActivityInterface>(`${this.baseUrl}/${id}`, {
              params: this.generateHttpParams(parameters),
            })
            .pipe(
              map((result) => {
                if (result) {
                  const activity = Activity.fromInterface(result);
                  this._data.next(activity);

                  return activity;
                } else {
                  this._data.next(result);
                  return result;
                }
              })
            );
        }
      })
    );
  }

  public get data(): Observable<Activity | null> {
    return this._data.asObservable();
  }

  public set $data(activity: Activity | null) {
    this._data.next(activity);
  }
}
