import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DateFnsAdapter, MAT_DATE_FNS_FORMATS, MatDateFnsModule } from '@angular/material-date-fns-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';
import { enUS } from 'date-fns/locale';
import { AuthModule, AuthService } from './auth';
import { AbilityModule } from './auth/permissions';
import { DynamicComponentDirective } from './directive';
import { PublicAuthInterceptor } from './http/interceptors';
import {
  AccessDeniedLayoutComponent,
  AuthenticationLayoutComponent,
  EmbeddedLayoutComponent,
  HomeLayoutComponent,
  LoaderLayoutComponent,
  MainLayoutComponent,
  PageNotFoundLayoutComponent,
} from './layouts';
import { AppInitService } from './services';

export function initializeApp(initService: AppInitService, authService: AuthService) {
  return () => initService.init(authService);
}

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    RouterModule,
    TranslocoModule,
    AbilityModule,
    AuthModule,

    // Material dependencies
    MatDateFnsModule,
    MatButtonModule,
    MatCardModule,
    MatDividerModule,
    MatIconModule,
    MatFormFieldModule,
    MatListModule,
    MatSelectModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatToolbarModule,
    MatTooltipModule,
  ],
  exports: [
    HomeLayoutComponent,
    MainLayoutComponent,
    PageNotFoundLayoutComponent,
    AccessDeniedLayoutComponent,
    AuthenticationLayoutComponent,
    LoaderLayoutComponent,
    EmbeddedLayoutComponent,

    DynamicComponentDirective,
  ],
  declarations: [
    HomeLayoutComponent,
    MainLayoutComponent,
    PageNotFoundLayoutComponent,
    AccessDeniedLayoutComponent,
    AuthenticationLayoutComponent,
    LoaderLayoutComponent,
    EmbeddedLayoutComponent,

    DynamicComponentDirective,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AppInitService, AuthService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: PublicAuthInterceptor,
      multi: true,
    },
    {
      provide: MAT_DATE_LOCALE,
      useValue: enUS,
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: MAT_DATE_FNS_FORMATS,
    },
    {
      provide: DateAdapter,
      useClass: DateFnsAdapter,
      deps: [MAT_DATE_LOCALE, MAT_DATE_FORMATS],
    },
  ],
})
export class CoreModule {}
