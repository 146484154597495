import { Component, ViewEncapsulation } from '@angular/core';
import { BaseMaterialLayoutComponent } from 'src/app/shared/components/base';

@Component({
  selector: 'app-embedded-layout',
  template: ` <router-outlet></router-outlet> `,
  styleUrls: ['./embedded-layout.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class EmbeddedLayoutComponent extends BaseMaterialLayoutComponent {}
