import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { environment } from 'src/environments/environment';
import { canActivatePermission } from './core/auth/guards';
import {
  AccessDeniedLayoutComponent,
  EmbeddedLayoutComponent,
  HomeLayoutComponent,
  MainLayoutComponent,
  PageNotFoundLayoutComponent,
} from './core/layouts';

const routes: Routes = [
  { path: '', redirectTo: `home`, pathMatch: 'full' },
  {
    path: '',
    children: [
      {
        path: 'event',
        component: MainLayoutComponent,
        loadChildren: () => import('./modules/activity/activity.module').then((m) => m.DashboardModule),
        canActivate: [canActivatePermission],
        data: {
          permissionsRequired: {
            subject: 'activity',
            action: 'view',
          },
        },
      },
      {
        path: 'wv-event',
        component: EmbeddedLayoutComponent,
        loadChildren: () => import('./modules/activity/activity.module').then((m) => m.DashboardModule),
        canActivate: [canActivatePermission],
        data: {
          permissionsRequired: {
            subject: 'activity',
            action: 'view',
          },
        },
      },
      {
        path: 'home',
        component: HomeLayoutComponent,
        loadChildren: () => import('./modules/homepage/homepage.module').then((m) => m.HomepageModule),
      },
    ],
  },
  {
    path: 'page-not-found',
    component: PageNotFoundLayoutComponent,
  },
  {
    path: 'access-denied',
    component: AccessDeniedLayoutComponent,
  },
  { path: '**', redirectTo: '/page-not-found', pathMatch: 'full' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'top',
      enableTracing: environment.production,
      anchorScrolling: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
