import { Platform } from '@angular/cdk/platform';
import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { Subject } from 'rxjs';
import { LiveService, LoaderService } from 'src/app/core/services';

@Component({
  selector: 'app-base-component',
  template: ``,
})
export class BaseComponent implements OnInit, OnDestroy {
  protected unsubscribe: Subject<void> = new Subject();

  protected loaderService = inject(LoaderService);
  protected liveService = inject(LiveService);
  protected platform = inject(Platform);

  public loading = false;
  public error = false;
  public isMobile = false;

  ngOnInit(): void {
    this.loading = false;
    this.error = false;

    if (this.platform.ANDROID || this.platform.IOS) {
      this.isMobile = true;
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
