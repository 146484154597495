import { Injectable } from '@angular/core';
import { AuthConfig, OAuthEvent, OAuthService, TokenResponse } from 'angular-oauth2-oidc';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
import { Observable, from, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private readonly oauthService: OAuthService, private readonly authConfig: AuthConfig) {
    this.oauthService.events.subscribe((e: OAuthEvent) => {
      switch (e.type) {
        case 'token_expires':
          console.info('Token expired');
          this.oauthService
            .refreshToken()
            .then(() => console.info('Access token refreshed'))
            .catch(() => {
              console.error('Error when refreshing token');
            });
          break;
      }
    });
  }

  public initAuth(): Observable<boolean> {
    this.oauthService.configure(this.authConfig);
    this.oauthService.tokenValidationHandler = new JwksValidationHandler();

    this.oauthService.setupAutomaticSilentRefresh();

    return from(this.oauthService.tryLoginImplicitFlow()).pipe(
      take(1),
      switchMap((isLoggedIn: boolean) => {
        return of(isLoggedIn);
      })
    );
  }

  public authenticate(username: string, password: string): Observable<TokenResponse> {
    return from(this.oauthService.fetchTokenUsingPasswordFlow(username, password)).pipe(
      map((resp: TokenResponse) => {
        return resp;
      })
    );
  }

  public logout(): void {
    this.oauthService.logOut();
  }
}
