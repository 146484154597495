import { MediaMatcher } from '@angular/cdk/layout';
import { DOCUMENT, Location } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { BaseMaterialLayoutComponent } from 'src/app/shared/components/base';
import { AuthService } from '../../auth';

@Component({
  selector: 'app-access-denied-layout',
  templateUrl: './access-denied-layout.component.html',
  styleUrls: ['./access-denied-layout.component.scss'],
})
export class AccessDeniedLayoutComponent extends BaseMaterialLayoutComponent implements OnInit {
  constructor(
    @Inject(DOCUMENT) protected override document: Document,
    protected override media: MediaMatcher,
    private authService: AuthService,
    private location: Location
  ) {
    super(document, media);
  }

  override ngOnInit(): void {
    super.ngOnInit();

    this.loaderService.setLoadingFullScreen(false);
  }

  public returnToLogin(): void {
    this.authService.logout();
  }

  public returnToPreviousPage(): void {
    this.location.back();
  }
}
