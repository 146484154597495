import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  private _$loadingFullScreen: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  private _loadingMessage = '';

  public get $loadingFullScreen(): Observable<boolean> {
    return this._$loadingFullScreen.asObservable();
  }

  public get loadingFullScreen(): boolean {
    return this._$loadingFullScreen.getValue();
  }

  public setLoadingFullScreen(loading: boolean, message?: string) {
    if (message) {
      this._loadingMessage = message;
    } else {
      this._loadingMessage = '';
    }

    this._$loadingFullScreen.next(loading);
  }

  public get loadingMessage(): string {
    return this._loadingMessage;
  }
}
