import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';
import { AppAbility, PermissionActions, PermissionSubjects } from '../permissions';

export interface PermissionGuardData {
  subject: PermissionSubjects;
  action: PermissionActions;
}

export const canActivatePermission: CanActivateFn = (route: ActivatedRouteSnapshot) => {
  const permissionsRequired = route.data && (route.data['permissionsRequired'] as PermissionGuardData);
  const canActivate =
    permissionsRequired && permissionsRequired.action && permissionsRequired.subject
      ? inject(AppAbility).can(permissionsRequired.action, permissionsRequired.subject)
      : true;
  return canActivate ? canActivate : inject(Router).parseUrl('/access-denied');
};
