import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { debounceTime, distinctUntilChanged, take, takeUntil } from 'rxjs';
import { HttpService } from 'src/app/core/http/services';
import { BaseComponent } from '.';

export interface GenericDataTableColumnsInterface {
  key: string;
  translation: string;
}

@Component({
  selector: 'app-data-table-component',
  template: ``,
})
export abstract class GenericDataTableComponent<T> extends BaseComponent implements OnInit, OnDestroy {
  public availableColumns: GenericDataTableColumnsInterface[] = [];
  public selectedColumns: GenericDataTableColumnsInterface[] = [];

  public dataSource: MatTableDataSource<T> = new MatTableDataSource<T>();
  public selection = new SelectionModel<T>(true, []);
  public columnsToDisplayWithExpand: GenericDataTableColumnsInterface[] = [];
  public expandedElement: T | null = null;

  public pageSizeOptions: number[] = [5, 10, 25, 100];

  public activePagination: PageEvent = {
    pageIndex: 0,
    pageSize: 25,
    length: 0,
  };
  public activeSort: Sort | null = null;

  public searchControl: FormControl<string | null> = new FormControl('', {
    validators: [],
  });

  constructor(protected httpService: HttpService<T>) {
    super();
  }

  override ngOnInit(): void {
    super.ngOnInit();

    this.searchControl.valueChanges
      .pipe(takeUntil(this.unsubscribe), distinctUntilChanged(), debounceTime(500))
      .subscribe(() => {
        this.loadData();
      });

    this.loadData();
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  public isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  public masterToggle(): void {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  public onPageChange(page: PageEvent): void {
    this.activePagination = page;
    this.loadData();
  }

  public onSortChange(sort: Sort): void {
    this.activeSort = sort;
    this.loadData();
  }

  protected loadData(): void {
    this.loading = true;

    this.httpService
      .list(this.queryParameters)
      .pipe(take(1))
      .subscribe((items) => {
        this.activePagination.length = items.length;
        this.dataSource.data = items;
        this.loading = false;
      });
  }

  protected get searchParameters(): object {
    return {};
  }

  protected get paginationParameters(): object {
    return {
      page: this.activePagination.pageIndex + 1,
      limit: this.activePagination.pageSize,
    };
  }

  protected get orderingParameters(): object {
    if (!this.activeSort || !this.activeSort.active) {
      return {};
    }

    const direction = this.activeSort.direction === 'desc' ? '-' : '';
    const field = this.activeSort.active;

    return { ordering: `${direction}${field}` };
  }

  protected get queryParameters(): object {
    return {
      ...this.searchParameters,
      ...this.orderingParameters,
      ...this.paginationParameters,
    };
  }

  public get displayedColumns(): string[] {
    const displayColumns = this.selectedColumns.map((c) => c.key);
    return displayColumns;
  }
}
