import { AuthConfig, OAuthModuleConfig, OAuthResourceServerConfig } from 'angular-oauth2-oidc';
import { environment } from 'src/environments/environment';

export const authConfig: AuthConfig = environment.oauth;

export class OAuthModuleCustomConfig extends OAuthModuleConfig {
  override resourceServer: OAuthResourceServerConfig = {
    allowedUrls: environment.api?.baseUrl ? [environment.api.baseUrl] : [],
    sendAccessToken: true,
  };
}

export function createDefaultStorage(): Storage | null {
  return typeof localStorage !== 'undefined' ? localStorage : sessionStorage;
}

export function createDefaultLogger(): Console {
  return console;
}
