import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { takeUntil } from 'rxjs';
import { DynamicComponentDirective } from './core/directive';
import { LoaderLayoutComponent } from './core/layouts';
import { BaseComponent } from './shared/components/base';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent extends BaseComponent implements OnInit, AfterViewInit {
  @ViewChild(DynamicComponentDirective, { static: true })
  dynamicComponentHost: DynamicComponentDirective | undefined;

  constructor(private router: Router) {
    super();
  }

  override ngOnInit(): void {
    super.ngOnInit();

    this.loaderService.$loadingFullScreen.pipe(takeUntil(this.unsubscribe)).subscribe({
      next: (loading) => {
        if (!this.dynamicComponentHost) {
          return;
        }

        const viewContainerRef = this.dynamicComponentHost.viewContainerRef;
        viewContainerRef.clear();

        if (loading) {
          const viewContainerRef = this.dynamicComponentHost.viewContainerRef;
          viewContainerRef.clear();

          viewContainerRef.createComponent<LoaderLayoutComponent>(LoaderLayoutComponent);
        }
      },
    });

    this.loaderService.setLoadingFullScreen(true);
  }

  ngAfterViewInit(): void {
    this.router.events.pipe(takeUntil(this.unsubscribe)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const gtmTag = {
          event: 'page',
          pageName: event.url,
        };

        if ((window as any).dataLayer) {
          (window as any).dataLayer.push(gtmTag);
        }
      }
    });
  }
}
