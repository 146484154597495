import { Platform } from '@angular/cdk/platform';
import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BehaviorSubject, Observable, Subject, map, startWith } from 'rxjs';
import { ActivityService } from '../http/services';
import { TimeFrame } from '../models';

@Injectable({ providedIn: 'root' })
export class LiveService {
  public pointsOfInterest = [
    { label: 'Point relais', value: '1' },
    { label: 'Medical point', value: '2' },
    { label: 'Feed stations', value: '3' },
    { label: 'Cut off times', value: '52' },
    { label: 'Partner activation', value: '114' },
    { label: 'Tri - Run start', value: '201' },
    { label: 'Tri - Cycling start', value: '202' },
    { label: 'Kilometer point', value: '301' },
  ];

  public isStatsPanelOpen = true;
  public isElevationPanelOpen = true;

  private _currentTimeFrame: BehaviorSubject<TimeFrame | null> = new BehaviorSubject<TimeFrame | null>(null);
  private _currentTrackedTimeFrame: BehaviorSubject<TimeFrame | null> = new BehaviorSubject<TimeFrame | null>(null);

  public readonly fitMapBoundsOnActivity: Subject<boolean> = new Subject<boolean>();

  public pointsOfInterestVisibility: FormControl<string[] | null> = new FormControl(
    this.pointsOfInterest.map((p) => p.value)
  );

  constructor(private platform: Platform, private activityService: ActivityService) {
    if (this.platform.ANDROID || this.platform.IOS) {
      this.isStatsPanelOpen = false;
      this.isElevationPanelOpen = false;
    }
  }

  public get $currentTimeFrame(): Observable<TimeFrame | null> {
    return this._currentTimeFrame.asObservable().pipe(startWith(this._currentTimeFrame.getValue()));
  }

  public get currentTimeFrame(): TimeFrame | null {
    return this._currentTimeFrame.getValue();
  }

  public set currentTimeFrame(timeframe: TimeFrame | null) {
    this._currentTimeFrame.next(timeframe);
  }

  public get $currentTrackedTimeFrame(): Observable<TimeFrame | null> {
    return this._currentTrackedTimeFrame.asObservable().pipe(startWith(this._currentTrackedTimeFrame.getValue()));
  }

  public get currentTrackedTimeFrame(): TimeFrame | null {
    return this._currentTrackedTimeFrame.getValue();
  }

  public set currentTrackedTimeFrame(timeframe: TimeFrame | null) {
    this._currentTrackedTimeFrame.next(timeframe);
  }

  public get $isElevationGraphDisplayed(): Observable<boolean> {
    return this.activityService.data.pipe(
      map((activity) => {
        return !!activity && activity.displayElevationPanel === true;
      })
    );
  }
}
