import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map, of, retry } from 'rxjs';
import { WebSocketSubject, webSocket } from 'rxjs/webSocket';
import { environment } from 'src/environments/environment';
import { Activity } from '../../models';
import { LoggerService } from '../../services';
import { AthleteComputedDataInterface, instanceOfAthleteComputedDataArray } from '../interfaces';
import { ActivityService } from './activity.service';

@Injectable({
  providedIn: 'root',
})
export class ComputedDataService {
  private _currentActivity: Activity | null = null;
  private _ws: WebSocketSubject<unknown> | null = null;

  private _data: BehaviorSubject<AthleteComputedDataInterface[]> = new BehaviorSubject<AthleteComputedDataInterface[]>(
    []
  );

  constructor(protected http: HttpClient, protected logger: LoggerService, private activitiesService: ActivityService) {
    this.activitiesService.data.subscribe({
      next: (activity) => {
        if (activity && activity.id !== this._currentActivity?.id) {
          this._data.next([]);

          if (this._ws) {
            this._ws.complete();
          }

          this._ws = webSocket(`${environment.publicApi.baseWsUrl}activities/${activity.id}/computed-data`);
          this._ws
            .pipe(
              retry({
                delay: 3000,
                count: 10,
              }),
              map((value) => {
                if (value && instanceOfAthleteComputedDataArray(value)) {
                  const array = this._data.getValue();
                  value.forEach((v) => {
                    const index = array.findIndex((v2) => v.timeframe === v2.timeframe);

                    if (index >= 0) {
                      array[index] = v;
                    } else {
                      array.push(v);
                    }
                  });

                  this._data.next(array);
                }

                return value;
              })
            )
            .subscribe();
        }

        if (!activity) {
          if (this._ws) {
            this._data.next([]);
            this._ws.complete();
          }
        }
      },
    });
  }

  public get ws(): Observable<unknown | null> {
    return this._ws ? this._ws.asObservable() : of(null);
  }

  public get data(): Observable<AthleteComputedDataInterface[]> {
    return this._data.asObservable();
  }

  public get topRankData(): Observable<AthleteComputedDataInterface[]> {
    return this._data.asObservable().pipe(map((v) => v.filter((d) => d.data && d.data.rank && d.data.rank <= 5)));
  }
}
