import { Component, OnDestroy, OnInit, inject, signal } from '@angular/core';
import { takeUntil } from 'rxjs';
import { AthleteComputedDataInterface } from 'src/app/core/http/interfaces';
import { ComputedDataService, TimeFramesService } from 'src/app/core/http/services';
import { TimeFrame } from 'src/app/core/models';
import { BaseComponent } from '../base/base.component';

@Component({
  selector: 'app-simple-select-component',
  template: '',
})
export abstract class BaseAthleteTopComponent extends BaseComponent implements OnInit, OnDestroy {
  protected timeFrames: TimeFrame[] = [];
  protected athletesData = signal<AthleteComputedDataInterface[]>([]);

  protected timeFrameService = inject(TimeFramesService);
  protected dataService = inject(ComputedDataService);

  constructor() {
    super();
  }

  public override ngOnInit(): void {
    super.ngOnInit();

    this.timeFrameService.data.pipe(takeUntil(this.unsubscribe)).subscribe({
      next: (timeFrames) => {
        this.timeFrames = timeFrames ? timeFrames : [];
      },
    });

    this.dataService.topRankData.pipe(takeUntil(this.unsubscribe)).subscribe({
      next: (data) => {
        this.athletesData.mutate((value) => {
          data.forEach((d) => {
            const index = value.findIndex((v) => v.data?.rank === d.data?.rank);
            if (index >= 0) {
              value[index] = d;
            } else {
              value.push(d);
            }
          });
        });
      },
    });
  }

  public override ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
